<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="`*` + $t('Section Name') + `:`"
            label-cols-md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-form-input
                v-model="model.name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Title')"
                @input="generateSlug(model.name)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            class="my-2"
            @click="handleAddAvatar"
          >Thêm hình</b-button>
          <b-row>
            <b-col
              v-for="(item, index) in model.list"
              :key="index"
              cols="3"
              class="mb-2"
            >
              <div class="text-center">
                <b-img
                  class="avatar"
                  thumbnail
                  fluid
                  rounded="0"
                  :src="item.avatar"
                />
                <div class="my-2 d-flex">
                  <b-form-file
                    @change="previewFiles($event, index)"
                  />
                  <b-button
                    class="ml-1"
                    @click="handleDeleteItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </div>
                <b-form-input
                  v-model="item.link"
                  placeholder="Thêm đường dẫn liên kết"
                />
              </div>
            </b-col>
          </b-row>
          <b-button @click="handleSubmit">
            Save {{ sindex }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import Request from '@/plugins/service/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
  },
  props: {
    sindex: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      required,
      model: {
        id: null,
        name: null,
        list: [],
      },
      valueOrigin: [],
      isChangeImg: false,
    }
  },
  watch: {
    value() {
      // eslint-disable-next-line prefer-destructuring
      this.model = this.value[0]
    },
  },
  created() {
    this.getDetail()
  },
  methods: {
    generateSlug(name) {
      this.model.id = this.convertToSlug(name)
    },
    handleAddAvatar() {
      this.model.list.push({
        avatar: null,
        file: null,
        link: null,
        isChange: 1,
      })
    },
    handleDeleteItem(index) {
      // this.valueOrigin[this.sindex].list.splice(index, 1)
      this.model.list.splice(index, 1)
    },
    async getDetail() {
      const res = await this.mixGet('/setting/full_page')
      if (res.status) {
        this.valueOrigin = JSON.parse(res.data.value)
        // console.log(this.valueOrigin)
        // eslint-disable-next-line prefer-destructuring
        this.model = this.valueOrigin[this.sindex]
      }
    },
    async handleSubmit() {
      if (this.isChangeImg) {
        let listThumbnailId = null
        const listThumbnail = []
        let index = 0
        this.model.list.map(async (x, idx) => {
          if (Number(x.isChange) === 1) {
            const formData = new FormData()
            formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
            formData.append('type_table', 's_t')
            formData.append('type', 'desktop')
            formData.append('avatar', x.file)
            formData.append('table_field', 'list_thumbnail')
            const resIMG = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_IMG_URL}/images_only`,
              formData,
            )
            if (resIMG.status === 200) {
              if (listThumbnailId) {
                listThumbnailId += `,${resIMG.data.data.id}`
              } else {
                listThumbnailId = resIMG.data.data.id
              }
              this.model.list[idx].avatar = resIMG.data.data.image
              this.model.list[idx].isChange = 0
              listThumbnail.push(resIMG.data.data.image)
            }
          } else {
            this.model.list[idx].avatar = x.avatar
            listThumbnail.push(x.avatar)
          }

          index += 1
          if (index === this.model.list.length) {
            this.handleSentData()
          }
        })
      } else {
        this.handleSentData()
      }
    },
    async handleSentData() {
      this.valueOrigin[this.sindex] = this.model
      const params = {
        key: 'full_page',
        value: JSON.stringify(this.valueOrigin),
      }
      const res = await this.mixPut('/setting/full_page', params)
      if (res.status) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update success',
          },
        })
      }
    },
    previewFiles(input, index) {
      this.isChangeImg = true
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.list[index].avatar = e.target.result
      }
      this.model.list[index].file = file
      this.model.list[index].isChange = 1
      // console.log(this.model)
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
<style lang="scss" scoped >
.avatar {
  width: 400px;
  height: 200px;
}
</style>
