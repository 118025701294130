<template>
  <section>
    <b-card
      class="member-block"
      title="Thiết lập Fullpage"
    >
      <b-tabs class="mt-2">
        <b-tab
          title="Section 1"
          lazy
        >
          <SectionItem :sindex="0" />
        </b-tab>
        <b-tab
          title="Section 2"
          lazy
        >
          <SectionItem :sindex="1" />
        </b-tab>
        <b-tab
          title="Section 3"
          lazy
        >
          <SectionItem :sindex="2" />
        </b-tab>
        <b-tab
          title="Section 4"
          lazy
        >
          <SectionItem :sindex="3" />
        </b-tab>
        <b-tab
          title="Section 5"
          lazy
        >
          <SectionItem :sindex="4" />
        </b-tab>
      </b-tabs>

    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { VueGoodTable } from 'vue-good-table'
// import { required } from '@validations'
import SectionItem from './SectionItem.vue'

export default {
  components: {
    // ValidationObserver,
    BCard,
    BTabs,
    BTab,
    SectionItem,
  },
  data() {
    return {
      model: {
        value: [],
      },
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table.commission {
  .flex-wrap {
    display: flex;
    gap: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  strong {
    cursor: pointer;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
<style lang="scss">
.member-block{
  .list-title-tab{
    margin-top: 16px;
    display: flex;
    align-items: center;
    .one-item{
      padding: 6px 16px;
      cursor: pointer;
      &.active{
        border-bottom: 2px solid #7367f0;
        color: #7367f0;
      }
    }
  }
}
.ctrl-radio{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.img-avata{
  background-color: #f0f0f0;
  padding: 16px;
  text-align: center;
  img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.title-big-modal{
  font-size: 20px;
  color: #7367f0;
  font-weight: bold;
}
.modal-profile{
  .one-item{
    margin-bottom: 16px;
    .title-info{
      font-size: 13px;
      color: #3E444B;
      margin-bottom: 4px;
    }
    .content-info{
      font-size: 16px;
      color: #3E444B;
      font-weight: bold;
    }
  }
}
#modal-canhbao{
  .modal-header{
    background-color: #ECA124;
    .modal-title{
      color: #fff;
    }
  }
}
#modal-khoa{
  .modal-header{
    background-color: #F75E5E;
    .modal-title{
      color: #fff;
    }
  }
}
</style>
